function displayCCBilling(display)
{
	if(display)
	{
		$('.cc-more-options-fields').show('fast')
	}
	else
	{
		$('.cc-more-options-fields').hide('fast')
		$('[id^=creditcard_crypt_]').each(function() {
			if (this.id !== 'creditcard_crypt_number')
			{
				$(this).val('')
			}
		})
	}
}
function setupCleanTemplate() {

	$('#same-as-above-checkbox').change(function() {
		let display = !$(this).is(':checked')

		displayCCBilling(display)
	})

	// handles checkbox if billing address ISN'T displayed
	$('#more-options-checkbox').change(function() {
		let display = $(this).is(':checked')

		displayCCBilling(display)
	})

	/**
	 * Show credit card fields when credit card radio is clicked.
	 */
	$('#creditcard-radio').on('click', function () {
		$('.ach-billing-fields').slideUp();
		$('.ach-payment-option').css({'border-bottom-left-radius': '4px', 'border-bottom-right-radius': '4px'});
		$('.cc-billing-fields').slideDown(400);
	});

	/**
	 * Show ach/eCheck fields when ach/eCheck radio is clicked.
	 */
	$('#ach-radio').on('click', function () {
		$('.cc-billing-fields').slideUp();
		$('.ach-payment-option').css({'border-bottom-left-radius': '0', 'border-bottom-right-radius': '0'});
		$('.ach-billing-fields').slideDown(400);
	});

	/**
	 * Show additional credit card billing fields.
	 */
	$('.cc-billing-fields .btn-more-options').on('click', function (event) {
		event.preventDefault();
		if ($('.cc-billing-fields .btn-more-options').text() == 'More Options') {
			$(this).text('Hide Options');
		} else {
			$(this).text('More Options');
		}
		$('.cc-more-options-fields').slideToggle(400);
	});

	/**
	 * Show additional ach billing fields.
	 */
	$('.ach-billing-fields .btn-more-options').on('click', function (event) {
		event.preventDefault();
		if ($('.ach-billing-fields .btn-more-options').text() == 'More Options') {
			$(this).text('Hide Options');
		} else {
			$(this).text('More Options');
		}
		$('.ach-more-options-fields').slideToggle(400);
	});

	/**
	 * Show the quantity field.
	 */
	$('.btn-edit-order').on('click', function (event) {
		event.preventDefault();

		$('.quantity-field').removeAttr('hidden');
	});



	if($('.handle_selectCountry.billing').length > 0)
	{
		handleStateInput('billing');
	}

	if($('.handle_selectCountry.credit').length > 0)
	{
		handleStateInput('credit');
	}

	if($('.handle_selectCountry.shipping').length > 0)
	{
		handleStateInput('shipping');
	}
}

function handleStateInput(type){

	////////////// State Input Elements ////////////
	const defaultStateInput = $(`.handle_defaultStateTextInput.${type}`)
	const usStateSelect2 = $(`.handle_selectUSStates.${type}`)
	const canadaProvinceSelect2 = $(`.handle_selectCanadaProvinces.${type}`)

	const select_country_input = $(`.handle_selectCountry.${type}`)
	let selected_country = select_country_input[0].selectedOptions[0].value;

	switchStateInputField(defaultStateInput, usStateSelect2, canadaProvinceSelect2, selected_country);

	//////////////// STATE INPUT WATCHER ///////////////////
	select_country_input.on('change', function()
	{
		selected_country = select_country_input[0].selectedOptions[0].value;
		switchStateInputField(defaultStateInput, usStateSelect2, canadaProvinceSelect2, selected_country);
	})
}

function switchStateInputField(defaultStateInput, usStateSelect2, canadaProvinceSelect2, country)
{
	if (country === 'United States')
	{
		defaultStateInput.hide()
		canadaProvinceSelect2.hide()
		usStateSelect2.show()
	}
	else if (country === 'Canada'){
		defaultStateInput.hide()
		usStateSelect2.hide()
		canadaProvinceSelect2.show()
	}
	else
	{
		usStateSelect2.hide()
		canadaProvinceSelect2.hide()
		defaultStateInput.show()
	}
}

module.exports = {
	setupCleanTemplate: setupCleanTemplate,
}
