/**
 * This toggles permission view display when administrating roles/users.
 */
$(document).ready(function() {
	if($('body').is('.controller_adminadmin')) {
		$("body").on('click', '.role-table .show_roles', function(event) {
			event.preventDefault();
			$(this).closest('tr').next().toggle()
		});

		$(".admin-table .show_roles").on('click', function(event) {
			event.preventDefault();
			$(this).closest('tr').next().toggle();
		});

		$(".admin-table .show_perms").on('click', function(event) {
			event.preventDefault();
			$(this).closest('tr').next().toggle();
		});
	}
});
