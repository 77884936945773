var url_codeEntered = '';

function configure(config)
{
	this.url_codeEntered = config.url_codeEntered;
}

/**
 * Get some details on the coupon for the signup ui
 */
function codeEntered()
{
	$('#couponDescription').removeClass('alert alert-success alert-danger');
	$('#couponCode').val('');
	var response = '';

	$.ajax({
		type: "POST",
		url: this.url_codeEntered,
		data: {
			'code': $('#coupon').val()
		},
		success: function(data) {
			response = data;
		},
		dataType: 'json',
		async: false
	});

	if (response)
	{
		$('#couponDescription').show().addClass('alert alert-success');

		$('#couponDescription p').html(response.descrip);
		$('#couponCode').val(response.code);
	}
	else
	{
		$('#couponDescription').show().addClass('alert alert-danger');
		$('#couponDescription p').html('Not a valid coupon.');
	}
}

module.exports = {
	configure: configure,
	codeEntered: codeEntered
}
