import $ from 'jquery';

window.jQuery = $;
window.$ = $;

// node module stylesheets/dependencies
import 'popper.js';
import 'bootstrap';
import 'jquery-validation/dist/jquery.validate';
import 'jquery-validation/dist/additional-methods';

import 'jquery-ui/themes/base/all.css';

import '../assets/coc1/stylesheets/scss/styles.scss';

require('jquery-ui');
require('jquery-ui/ui/effects/effect-slide.js');
require('jquery-ui/ui/widgets/datepicker.js');
require('jquery-ui/ui/widgets/autocomplete.js');
require('./ui/permissions-role-table.js');

// Select2 for fancy selectboxes
import 'select2';                       // globally assign select2 fn to $ element
import 'select2/dist/css/select2.css';  // optional if you have css loader

// individual template js modules
const clean = require('./hostedsignups/_clean.js');

window.ChargeOver = window.ChargeOver || {};

window.ChargeOver.CoC = window.ChargeOver.CoC || {};

// Gateway stuff
window.ChargeOver.CoC.Stripe = require('./coc/stripe.js');

window.ChargeOver.Coupon = require('./hostedsignups/coupon.js');

$(document).ready(function() {

	// Signup form "clean" style
	clean.setupCleanTemplate();

	window.ChargeOver.CoC.Stripe.configure();

	// Ugh... what is this other stuff?
	$('.datepicker').each(function() {
		var opts = $(this).data('datepicker');

		$(this).datepicker(opts).on('change', function() {
			$(this).valid();
		});
	});

	$('.select2-enable').each(function() {
		var placeholder = $(this).attr('placeholder');
		$(this).select2({
			placeholder: placeholder,
			width: '100%'
		}).on('select2:close', function(e) {
			$(this).valid();
		});
	});

	// Default a specific payment method to show if there's only one of them
	if ($('.cc-billing-fields')[0] && $('.ach-billing-fields')[0])
	{
		//  No default, user can choose
		$('.cc-billing-fields').hide();
		$('.ach-billing-fields').hide();
	}
	else if ($('.cc-billing-fields')[0])
	{
		$('.cc-billing-fields').show();
		$('#creditcard-radio').prop('checked', true);
	}
	else if ($('.ach-billing-fields')[0])
	{
		$('.ach-billing-fields').show();
		$('#ach-radio').prop('checked', true);
	}

});
